$(function() {
    'use strict';

    const form = document.getElementById('contact')

    if (!form) {
        return false;
    }

    const submitButton = form.querySelector('button[type="submit"]')

    window.interactsWithCaptcha = function() {
        grecaptcha.render('g-recaptcha', {
            sitekey: "6LcGPr0ZAAAAALzb0BCUDCGephRIfrY4-n8IHNAs",
            callback: function(...argscallback) {
                argscallback.length ? submitButton.classList.remove('d-none') : submitButton.classList.add('d-block')
            },
            'expired-callback': function() {
                submitButton.classList.add('d-none')

                grecaptcha.reset()
            }
        })
    }

    form.addEventListener('submit', function(e) {
        e.preventDefault()
        e.stopPropagation()

        let originalSubmitButton = {
            html: $(submitButton).html(),
            classes: $(submitButton).attr('class')
        }

        $(submitButton).attr({
            disabled: true,
            class: 'btn btn-success btn-lg rounded-0 mt-3 font-weight-bold disabled'
        }).html(' <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Enviando...')

        $.ajax({
            url: form.action,
            type: 'POST',
            data: $(form).serialize(),
        }).done(function(response) {
            $('#results, #success').fadeIn()

            $('#success').find('p').html(response)

            form.reset()

            grecaptcha.reset()

            window.setTimeout(function() {
                $('#success').fadeOut(1000, 0)
            }, 5000)
        }).fail(function(error) {
            $('#results, #error').fadeIn()

            $('#error').find('p').html(error.responseText)

            grecaptcha.reset()
        }).always(function() {
            $(submitButton).attr({
                disabled: false,
                class: originalSubmitButton.classes.concat(' d-none')
            }).html(originalSubmitButton.html)
        })
    })
});