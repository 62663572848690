$.fn.modal.prototype.constructor.Constructor.Default.backdrop = 'static'

$.fn.modal.prototype.constructor.Constructor.Default.keyboard = false

$('[data-toggle="tooltip"]').tooltip()

$('[data-toggle="popover"]').popover()

$('[data-mask-phone="true"]').each(function() {
    new Cleave($(this), {
        phone: true,
        phoneRegionCode: 'MX'
    })
})