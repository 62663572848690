require('alpinejs');

// const eloquent = new localStorageDB('eloquent', localStorage)

// if (!eloquent.tableExists('carts')) {
//     eloquent.createTable('carts', [
//         'uuid', 'name', 'quantity', 'presentation'
//     ])

//     eloquent.commit()
// }

function interactsWithCart() {
    return {
        requiresBilling: false,
        productsOnCart: [],
        quantities: [...Array(10).keys()].map(item => ++item),
        maxItemsOnCart: 10,
        isLoading: false,
        requestHasErrors: false,
        requestWasSuccessful: false,
        responseTitle: null,
        responseMessage: null,
        init() {

        },
        fetchCart() {

        },
        addToCart(event) {
            let data = event.target.dataset
        },
        deleteFromCart(product) {

        },
        updateFromCart(product, field, event) {

        },
        clearCart() {

        },
        findOnCart(uuid) {

        },
        sendOrderRequest($refs) {
            let self = this

            self.isLoading = true

            self.requestWasSuccessful = self.requestHasErrors = self.requiresBilling = false

            $.ajax({
                url: $refs.form.action,
                type: $refs.form.method,
                data: $($refs.form).serialize(),
            }).done(function(response) {
                $refs.form.reset()
                self.requiresBilling = false
                self.requestWasSuccessful = true
                $('#state').trigger('change')
                self.responseTitle = 'Solicitud Enviada'
                self.responseMessage = response
                self.clearCart()
            }).fail(function(error) {
                self.requestHasErrors = true
                self.responseTitle = 'Solicitud No Enviada'
                self.responseMessage = error.responseText
                console.log(error)
            }).always(function() {
                self.isLoading = false
            })
        }
    }
}