$(function() {
	const navBar = $('.navbar')

	const handleNavBarAnimation = () => window.innerWidth >= 768 ? navBar.removeClass('bg-dark').addClass('bg-dark-500') : navBar.removeClass('bg-dark').removeClass('bg-dark-500')

	handleNavBarAnimation()

	window.onresize = _ => handleNavBarAnimation()

	navBar.on('show.bs.collapse', event => $(event.currentTarget).removeClass('bg-dark-500').addClass('bg-dark'))

	navBar.on('hidden.bs.collapse', event => $(event.currentTarget).removeClass('bg-dark').removeClass('bg-dark-500'))
})